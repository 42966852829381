<template>
  <div style="width:100%;height: 100%;text-align: center;">
    <div style="padding-top: 0.4rem">
      <span style="color: white;font-size: 0.2rem;">当前时点余额（ {{valueInfo.currBalanceUnit}} ）</span>
    </div>
    <div class="showNum">
      {{valueInfo.currBalance | numFilter}}
    </div>
    <!-- <div >
        <DigitRoll
                ref='digitroll'
                rollDigits='00000000000'
                :flipStra = "true"
                style="color: white;font-size: 60px;width: 30vw;font-weight: bold"
        />
    </div> -->
    <!-- <div>
      <span style="color: white;font-size: 30px;font-weight: bold">有效数据站点</span>
      <span style="color: white;font-size: 30px;font-weight: bold;color: rgba(255,215,0,1)">{{valueInfo.stationCount}}</span>
      <span style="color: white;font-size: 30px;font-weight: bold">个</span>
      <span style="color: white;font-size: 30px;font-weight: bold;margin-left: 100px">当前卡数</span>
      <span style="color: white;font-size: 30px;font-weight: bold;color: rgba(255,215,0,1)">{{valueInfo.cardNum}}</span>
      <span style="color: white;font-size: 30px;font-weight: bold">张</span>
    </div> -->

    <!--        <button @click='changeDigit'>click me</button>-->
  </div>
</template>

<script>
import DigitRoll from '@huoyu/vue-digitroll';
export default {
  name: "amountView",
  props: ['valueInfo'],
  components: { DigitRoll },
  data () {
    return {
      // digits: '0000000000',
      input: '',
      html : ''
    }
  },
  filters: {
    numFilter (value) {
        return (+value || 0).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
    },
  },
  // watch: {
  //   valueInfo (newVal, oldVal) {
  //     this.changeDigit();
  //   },
  // },
  // destroyed () {
  //   if (this.timer) {  // 注意在vue实例销毁前，清除我们的定时器
  //     clearInterval(this.timer);
  //   }
  // },
  mounted () {
    // this.changeDigit();
    // // // 定时刷新时间
    // this.timer = setInterval(() => {
    //   this.changeDigit() // 修改数据date
    // }, 10000)
  },

  methods: {
    changeDigit () {
      var tmpValue = parseInt(this.valueInfo.currBalance).toString();
      if (tmpValue.length < 11) {
        var addNum = 11 - parseInt(tmpValue.length.toString())
        for (var i = 0; i < addNum; i++) {
          tmpValue = '0' + tmpValue;
        }
      }
      var self = this;
      setTimeout(function () {
        self.$refs.digitroll.setDigit(self.toFormatData(tmpValue));
      }, 10000);

    },
    toFormatData (amount) {
      var tmpList = amount.split('')
      var list = [];
      for (var i = 0; i < tmpList.length; i++) {
        var tmpDic = {};
        tmpDic.value = tmpList[i];
        tmpDic.dur = (tmpList.length - i) * 300;
        list.push(tmpDic);
      }
      return list;
    }
  }
}
</script>

<style scoped lang="less">
.d-roll-list .d-roll-item {
  list-style: none;
  float: left;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
  background: rgba(111, 111, 111, 0.3);
  margin: 2px;
}
.showNum {
  color: #3de6ff;
  font-size: 1rem;
  font-family: "UnidreamLED";
}
@font-face {
    font-family: "UnidreamLED";
    src: url("./../../assets/fonts/LESLIE.TTF") format("truetype"); //UnidreamLED.ttf在项目中的位置
}

</style>