import { render, staticRenderFns } from "./amountView.vue?vue&type=template&id=2a8ff1f1&scoped=true"
import script from "./amountView.vue?vue&type=script&lang=js"
export * from "./amountView.vue?vue&type=script&lang=js"
import style0 from "./amountView.vue?vue&type=style&index=0&id=2a8ff1f1&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a8ff1f1",
  null
  
)

export default component.exports