<template>
  <div class="big-data">
    <div class="content">
      <div class="header">
        <div class="title">村口壹站数字化运营平台</div>
        <img src="@/assets/img/bigData/header.png" class="header-img">
      </div>
      <!--            业绩净增top5-->
      <div class="box yeji-top">
        <div class="title">• {{pushData.firstTop.name}}</div>
        <top-view :type="'yeji'" :showColor="'rgba(88,137,255,1)'" :valueInfo="pushData.firstTop.value"></top-view>
      </div>
      <!--            银行业绩top5-->
      <div class="box bank-top">
        <div class="title">• {{pushData.secondTop.name}}</div>
        <top-view :type="'bank'" :showColor="'rgba(255,215,0,1)'" :valueInfo="pushData.secondTop.value"></top-view>
      </div>
      <!-- 乡村特色服务 -->
      <div class="box special-country">
        <div class="title">• 乡村特色服务</div>
        <specialServiceCell v-for="(item,index) in pushData.operateDataList"
                            :startValue="item.operateValue"
                            :key="index"
                            :index="index"
                            :title="item.operateName"
                            :unit="item.unit"
                            :interval="1000"
                            :isOnline="item.active == 1 ? true : false"
                            :iconName="bigDataImgBaseUrl+item.picUrl"
        ></specialServiceCell>
      </div>
      <!-- 地图-->
      <div class="map box">
        <!-- 时点余额 -->
        <amountView :valueInfo="pushData"></amountView>
        <!--  中国地图-->
        <div class="box china">
          <china :valueInfo="pushData"></china>
        </div>
        <!--  新建站点-->
        <div class="box marqueeRollUpView">
          <MarqueeRollUpView :valueInfo="pushData"></MarqueeRollUpView>
        </div>
      </div>
      <!-- 站点卡数 -->
      <div class="box count flex">
        <div class="one">
          <div>已上线站点数</div>
          <div><span>{{pushData.stationCount}}</span> 个</div>
        </div>
        <div>
          <div>客户数</div>
          <div><span>{{pushData.cardNum}}</span> </div>
        </div>
      </div>
      <!--            时间余额旭日图-->
      <div class="box sun">
        <div class="title">• 业绩分布</div>
        <pieView :valueInfo="pushData"></pieView>
        <div class="desc">外环:时点余额</div>
        <div class="desc">内环:客户数</div>
      </div>
      <!--            业绩趋势图-->
      <div class="box qushi">
        <div class="title">• 站点业绩趋势图</div>
          <lineView :valueInfo="pushData" style="left: 1vh"></lineView>
      </div>
    </div> 
  </div>
</template>

<script>
    import {bigDataImgBaseUrl} from '../../utils/api';
    import china from "./china";
    import topView from "./topView";
    import MarqueeRollUpView from "./MarqueeRollUpView";
    import pieView from "./pieView";
    import lineView from "./lineView";
    import amountView from "./amountView";
    import timeView from "./timeView";
    import specialServiceCell from "./specialServiceCell";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name: "bigDataScreen",
        components: {china,topView,MarqueeRollUpView,pieView,lineView,amountView,timeView,specialServiceCell},
        data() {
            return{
                pushData:{},
                liveInfo:'h5 is live a',
                bigDataImgBaseUrl
            }
        },
        methods: {
            //初始化weosocket
            initWebSocket() {
                if (typeof WebSocket === "undefined") {
                    alert("您的浏览器不支持WebSocket");
                    return false;
                }
                // var wsuri = 'ws://(后端WebSocket地址)/websocket/' + this.userId + '/' + this.appid // websocket地址
                // var wsuri = 'ws://10.101.0.93:8089/websocket/bigScreen'
                // var wsuri = 'ws://172.23.42.139:8090/websocket/bigScreen'// var wsuri =
                // var wsuri = 'wss://h5.cunkouyizhan.com/screen/websocket/bigScreen'
                var wsuri = process.env.NODE_ENV == 'production' && window.location.href.indexOf('sit-villagestation') == -1 ? 'wss://h5.cunkouyizhan.com/screen/websocket/bigScreen' :'wss://sit-admin.wowfintech.net/screen/websocket/bigScreen'
                    this.websock = new WebSocket(wsuri);
                this.websock.onopen = this.websocketonopen;
                this.websock.onmessage = this.websocketonmessage;
                this.websock.onerror = this.websocketonerror;
                this.websock.onclose = this.websocketclose;
            },
            //连接成功
            websocketonopen() {
                console.log("WebSocket连接成功");
                if (this.restartTimer) {  // 注意在vue实例销毁前，清除我们的定时器
                    clearInterval(this.restartTimer);
                }
                // 添加心跳检测，每30秒发一次数据，防止连接断开（这跟服务器的设置有关，如果服务器没有设置每隔多长时间不发消息断开，可以不进行心跳设置）
                let self = this;
                this.timer = setInterval(() => {
                    try {
                        self.websock.send(this.liveInfo)
                    }catch(err){
                        console.log('断开了：' + err);
                        // self.restartSocket();
                    }
                }, 30000)
            },
            //接收后端返回的数据，可以根据需要进行处理
            websocketonmessage(e) {
                console.log('收到消息----',e,'------');

                if (e.data.indexOf(this.liveInfo) != -1){
                    return;
                }
                let dataJson = JSON.parse(e.data);
                if (dataJson != undefined  && (Object.prototype.toString.call(dataJson) === '[object Object]')){
                    this.pushData = dataJson;
                    console.log('收到有效消息',this.pushData);
                }
            },
            //连接建立失败重连
            websocketonerror(e) {
                console.log(`连接失败的信息：`, e);
                // this.restartSocket(); // 连接失败后尝试重新连接
            },
            //关闭连接
            websocketclose(e) {
                let self = this;
                console.log("断开连接", e);
                this.restartTimer = setInterval(() => {
                    self.restartSocket();
                }, 120000)
            },
            closeSocket(){
                //页面销毁时关闭ws连接
                if (this.websock) {
                    this.websock.close(); // 关闭websocket
                }
                if (this.timer) {  // 注意在vue实例销毁前，清除我们的定时器
                    clearInterval(this.timer);
                }
                if (this.restartTimer) {  // 注意在vue实例销毁前，清除我们的定时器
                    clearInterval(this.restartTimer);
                }
            },
            restartSocket(){
                this.closeSocket();
                this.initWebSocket();
            },
            getMonth(){
                var  date = new Date()
                return date.getMonth() + 1
            }
        },
        created() {
            this.closeSocket();
            this.initWebSocket();
            uwStatisticAction('/bigDataScreen','数据大屏PC')
        },
        destroyed() {
            //页面销毁时关闭ws连接
            if (this.websock) {
                this.websock.close(); // 关闭websocket
            }
            if (this.timer) {  // 注意在vue实例销毁前，清除我们的定时器
                clearInterval(this.timer);
            }
            if (this.restartTimer) {  // 注意在vue实例销毁前，清除我们的定时器
                clearInterval(this.restartTimer);
            }
        },
        beforeDestroy () {
            this.websocketclose()
        },

    }
</script>

<style scoped lang="less">
.big-data{
  background: #111111;
  min-width: 19.2rem;
  min-height: 10.8rem;
  height: 100vh;
  background: url('../../assets/img/bigData/data_bg.png');
  background-size: 100% 100%;
  .content{
    // max-width: 14rem;
    // min-height: 7.87rem;
    // margin: 0 auto;
    // position: relative;
  }
  .box{
    position: absolute;
    background:rgba(0,0,0,0);
    .title{
      color: white;
      margin: 0.21rem 0;
      width: 100%;
      height: 0.34rem;
      line-height: 0.34rem;
      font-size: 0.2rem;
      margin-left: 0.15rem;
      padding-left: 0.1rem;
      background: linear-gradient(270deg, rgba(31, 76, 162, 0) 0%, #1F4CA2 100%);
      margin-bottom: 0.2rem;
      position: relative;
      &::before{
        content: '';
        width: 0.05rem;
        height: 0.34rem;
        background: linear-gradient(270deg, rgba(31, 76, 162, 0) 0%, #1F4CA2 100%);
        position: absolute;
        left: -0.06rem;
      }
    }
  }
  .bg{
    width: 100%;
    height: 100%;
  }
  .header{
    position: absolute;
    top: 0.3rem;
    left: 50%;
    transform: translateX(-50%);
    .title{
      color: #3DE6FF;
      font-size: 0.47rem;
      text-align: center;
      font-weight: bold;
    }
    .header-img{
      width: 13.6rem;
      height: 0.6rem;
      position: absolute;
      top: 0.4rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .yeji-top{
    width: 4.65rem;
    height: 2.62rem;
    left: .37rem;
    top: 1.59rem;
    z-index: 10;
    background: rgba(114, 114, 221, 0.18);
  }
  .bank-top{
    width: 4.65rem;
    height: 2.62rem;
    left: .37rem;
    top: 4.51rem;
    z-index: 10;
    background: rgba(114, 114, 221, 0.18);
  }
  .special-country{
    width: 4.65rem;
    height: 2.97rem;
    left: .37rem;
    top: 7.43rem;
    background: rgba(114, 114, 221, 0.18);
  }
  .count{
    width: 5.4rem;
    height: 1.1rem;
    right: .4rem;
    top: 1.4rem;
    background: linear-gradient(180deg, #052E7F 0%, #001337 100%);
    color: rgba(255, 255, 255, 0.65);
    font-size: .16rem;
    text-align: center;
    align-items: center;
    div{
      flex: 1;
    }
    .one{
      position: relative;
      &::after{
        content: '';
        position: absolute;
        width: 0.01rem;
        height: 0.7rem;
        background: linear-gradient(rgba(0,0,0,0.2) 0%, #1968FF 50%, rgba(0,0,0,0.2) 100%);
        right:0;
        top: 0;
      }
    }
    span{
      font-size: .38rem;
      color: #3DDDFF;
    }
  }
  .sun{
    width: 5.4rem;
    height: 3.7rem;
    right: .37rem;
    top: 2.8rem;
    background: rgba(114, 114, 221, 0.18);
    .desc{
      display: inline-block;
      color: rgba(255, 255, 255, 0.65);
      font-size: 0.16rem;
      margin-right: 0.5rem;
      margin-left: 0.28rem;
    }
  }
  .qushi{
    width: 5.4rem;
    height: 3.6rem;
    right: .37rem;
    top: 6.8rem;
    background: rgba(114, 114, 221, 0.18);
  }
  .map{
    width:8.4rem;
    height: 8.4rem;
    top: 1rem;
    left: 50%;
    transform: translateX(-55%);
    .china{
      width: 8.4rem;
      height: 8.4rem;
      top: 0.8rem;
    }
    .marqueeRollUpView{
      width:5.8rem;
      height: 0.5rem;
      bottom: 0.1rem;
      left: 50%; 
      transform: translateX(-50%);
    }
  }
}
    
</style>
