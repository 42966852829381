<template>
  <div class="flex special-wrap" :class="{'rightBackground': index%2 == 0}">
    <div style="
      font-size: 18px;
      margin-top: 1vh;
      margin-left: 0.1rem;
      color: white;">{{this.title}}</div>
    <div style="
      font-size: 18px;
      margin-top: 1vh;
      color: #2FFAFF;">
      <b v-if="isOnline">{{startValue |formatterValue}} {{this.unit}}</b>
      <b v-else>待上线</b>
    </div>

  </div>
</template>

<script>
import vns from 'vue-number-scroll'

export default {
  name: "specialServiceCell",
  components: { vns },
  props: ['startValue', 'isAddFloat', 'iconName', 'interval', 'title', 'isOnline', 'index', 'unit'],
  data () {
    return {
      startNum: 0,
      endNum: 0,
    }
  },
  filters: {
    formatterValue (val) {
      if (val == 0) {
        return '待上线'
      }
      return val
    }
  },
  destroyed () {
    if (this.timer) {  // 注意在vue实例销毁前，清除我们的定时器
      clearInterval(this.timer);
    }
  },
  mounted () {
    this.endNum = this.startValue;
    this.toRestart();
    // 定时刷新时间
    this.timer = setInterval(() => {
      this.toRestart(); // 修改数据date
    }, 6000)
    window.onresize = () => {
    };
  },
  methods: {
    toRestart () {
      this.startNum = JSON.parse(JSON.stringify(this.endNum));
      if (this.isAddFloat) {
        this.endNum = (parseFloat((Math.round(Math.random() * this.interval * 100) / 100 + this.endNum).toFixed(2).toString()));
      } else {
        this.endNum = (Math.round(Math.random() * this.interval) + this.endNum);
      }
    }
  }
}
</script>

<style scoped lang="less">
.special-wrap {
  justify-content: space-between;
  // align-items: center;
  height: 0.5rem;
  line-height: 0.3rem;
  padding: 0 0.33rem 0 0.1rem;
}
.rightBackground{
  background: linear-gradient(270deg, #052E7F 0%, #001337 100%);
}
.flex {
  display: flex;
}
</style>
