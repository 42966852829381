import { render, staticRenderFns } from "./MarqueeRollUpView.vue?vue&type=template&id=035ea5cc&scoped=true"
import script from "./MarqueeRollUpView.vue?vue&type=script&lang=js"
export * from "./MarqueeRollUpView.vue?vue&type=script&lang=js"
import style0 from "./MarqueeRollUpView.vue?vue&type=style&index=0&id=035ea5cc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "035ea5cc",
  null
  
)

export default component.exports