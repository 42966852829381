<style lang="less">
.o-echarts {
  width: 8.4rem;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}
</style>
<template>
  <div :id="id" class="o-echarts"></div>
</template>

<script>
import echarts from 'echarts';
import JSON from './data-1528971808162-BkOXf61WX.json';
// import JSON from './all.json';
export default {
  name: 'china',
  props: ['valueInfo'],
  data () {
    return {
      id: 'echarts_' + 'china',
    }
  },
  watch: {
    valueInfo (newVal, oldVal) {
      this.toDraw();
    },
  },
  mounted () {
    this.toDraw();
    window.onresize = () => {
      this.echartObj.resize();
    };
  },
  methods: {
    toDraw () {
      // 行政区数据
      var geoCoordMap = {
        '台湾省': [121.5135, 25.0308],
        '黑龙江省': [127.9688, 45.368],
        '内蒙古自治区': [110.3467, 41.4899],
        "吉林省": [125.8154, 44.2584],
        '北京市': [116.4551, 40.2539],
        "辽宁省": [123.1238, 42.1216],
        "河北省": [114.4995, 38.1006],
        "天津市": [117.4219, 39.4189],
        "山西省": [112.3352, 37.9413],
        "陕西省": [109.1162, 34.2004],
        "甘肃省": [103.5901, 36.3043],
        "宁夏回族自治区": [106.3586, 38.1775],
        "青海省": [101.4038, 36.8207],
        "新疆维吾尔自治区": [87.9236, 43.5883],
        "西藏自治区": [91.11, 29.97],
        "四川省": [103.9526, 30.7617],
        "重庆市": [108.384366, 30.439702],
        "山东省": [117.1582, 36.8701],
        "河南省": [113.4668, 34.6234],
        "江苏省": [118.8062, 31.9208],
        "安徽省": [117.29, 32.0581],
        "湖北省": [114.3896, 30.6628],
        "浙江省": [119.5313, 29.8773],
        "福建省": [119.4543, 25.9222],
        "江西省": [116.0046, 28.6633],
        "湖南省": [113.0823, 28.2568],
        "贵州省": [106.6992, 26.7682],
        "云南省": [102.9199, 25.4663],
        "广东省": [113.12244, 23.009505],
        "广西壮族自治区": [108.479, 23.1152],
        "海南省": [110.3893, 19.8516],
        '上海市': [121.4648, 31.2891],
        "香港": [114.08, 22.20],
        '澳门': [113.33, 22.13],
      };
      // 展示数据
      var data = [
        { name: "北京市", value: 0 },
        { name: "天津市", value: 0 },
        { name: "河北省", value: 0 },
        { name: "山西省", value: 0 },
        { name: "内蒙古自治区", value: 0 },
        { name: "辽宁省", value: 0 },
        { name: "吉林省", value: 0 },
        { name: "黑龙江省", value: 0 },
        { name: "上海市", value: 0 },
        { name: "江苏省", value: 0 },
        { name: "浙江省", value: 0 },
        { name: "安徽省", value: 0 },
        { name: "福建省", value: 0 },
        { name: "江西省", value: 0 },
        { name: "山东省", value: 0 },
        { name: "河南省", value: 0 },
        { name: "湖北省", value: 0 },
        { name: "湖南省", value: 0 },
        { name: "重庆市", value: 0 },
        { name: "四川省", value: 0 },
        { name: "贵州省", value: 0 },
        { name: "云南省", value: 0 },
        { name: "西藏自治区", value: 0 },
        { name: "陕西省", value: 0 },
        { name: "甘肃省", value: 0 },
        { name: "青海省", value: 0 },
        { name: "宁夏回族自治区", value: 0 },
        { name: "新疆维吾尔自治区", value: 0 },
        { name: "广东省", value: 0 },
        { name: "广西壮族自治区", value: 0 },
        { name: "海南省", value: 0 },
        { name: "台湾省", value: 0 },
        { name: "香港", value: 0 },
        { name: "澳门", value: 0 },
      ];
      for (var i = 0; i < this.getObjectKeys(this.valueInfo.hasDataStationMap).length; i++) {
        for (var j = 0; j < data.length; j++) {
          if (data[j].name == this.getObjectKeys(this.valueInfo.hasDataStationMap)[i]) {
            data[j].value = this.getObjectValues(this.valueInfo.hasDataStationMap)[i]
          }
        }
      }

      var stationArr = []
      for (var k = 0; k < this.valueInfo.stationLocateList.length; k++) {
        stationArr.push({ value: [this.valueInfo.stationLocateList[k].lng, this.valueInfo.stationLocateList[k].lat] })
      }

      // var SHData = [
      //
      //     [{name:'上海'},{name:"上海",value:123}, ],
      //     [{name:'上海'},{name:"江苏",value:133},    ],
      //     // [{name:'上海'},{name:"浙江",value:114},   ],
      //     // [{name:'上海'},{name:"安徽",value:109},   ],
      //     // [{name:'上海'},{name:"河南",value:137},   ],
      //     // [{name:'上海'},{name:"湖北",value:116},   ],
      //     // [{name:'上海'},{name:"湖南",value:114},   ],
      //     // [{name:'上海'},{name:"重庆",value:91},    ],
      //     // [{name:'上海'},{name:"四川",value:125},   ],
      //     // [{name:'上海'},{name:"贵州",value:62},    ],
      //     [{name:'上海'},{name:"云南",value:133},    ],
      //     // [{name:'上海'},{name:"西藏",value:9},     ],
      //     // [{name:'上海'},{name:"甘肃",value:56},    ],
      //     // [{name:'上海'},{name:"青海",value:10},    ],
      //     // [{name:'上海'},{name:"宁夏",value:18},    ],
      //     // [{name:'上海'},{name:"广西",value:59},    ],
      // ];

      this.echartObj = echarts.init(document.getElementById(this.id));
      echarts.registerMap('china', JSON);

      // 处理需要展示的数据
      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value)
            });
          }
        }
        return res;
      };

      // 处理需要展示的数据
      var convertData11 = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value)
            });
          }
        }
        return res;
      };

      var formatData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var dataItem = data[i];
          var fromCoord = geoCoordMap[dataItem[0].name];
          var toCoord = geoCoordMap[dataItem[1].name];
          if (fromCoord && toCoord) {
            res.push([{
              coord: toCoord
            }, {
              coord: fromCoord
            }]);
          }
        }
        return res;
      };

      var option = {
        // 渐变色
        backgroundColor: 'transparent',// 背景色
        visualMap: {
          left: "center",
          bottom: "0%",
          // left:'20%',
          // icon: "circle",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 60,
          align: "auto",
          showLabel: true,
          text: ['', '有效数据站点数量'],
          orient: 'horizontal',
          type: 'piecewise',
          textStyle: {
            color: 'white',
            fontSize: 14,
          },
          padding: [60, 30, 10, 30],
          splitList: [
            {
              start: this.valueInfo.stationCountLevel.level1.bottom,
              end: this.valueInfo.stationCountLevel.level1.top,
              label: this.valueInfo.stationCountLevel.level1.desc,
              color: '#0095FF'
              // color: this.valueInfo.stationCountLevel.level1.color,

            },
            {
              start: this.valueInfo.stationCountLevel.level2.bottom,
              end: this.valueInfo.stationCountLevel.level2.top,
              label: this.valueInfo.stationCountLevel.level2.desc,
              color: '#0DEFCF'
              // color: this.valueInfo.stationCountLevel.level2.color,
            },
            {
              start: this.valueInfo.stationCountLevel.level3.bottom,
              end: this.valueInfo.stationCountLevel.level3.top,
              label: this.valueInfo.stationCountLevel.level3.desc,
              color: '#6459F7'
              // color: this.valueInfo.stationCountLevel.level3.color,
            },
            {
              start: this.valueInfo.stationCountLevel.level4.bottom,
              label: this.valueInfo.stationCountLevel.level4.desc,
              color: '#A4F88B'
              // color: this.valueInfo.stationCountLevel.level4.color,
            }
          ]
        },
        geo: { // 地图样式
          map: 'china',
          aspectScale: 0.75,
          itemStyle: {
            normal: {
              // shadowColor: '#276fce',
              // shadowOffsetX: 0,
              // shadowOffsetY: 15,
              opacity: 0.68,
              areaColor: '#406077',
            },
          },
        },// 地区样式
        series: [
          {
          type: 'lines',
          zlevel: 2,
          effect: {
            show: true,
            period: 4, //箭头指向速度，值越小速度越快
            trailLength: 0.2, //特效尾迹长度[0,1]值越大，尾迹越长重
            symbol: 'arrow', //箭头图标
            symbolSize: 5, //图标大小
          },
          lineStyle: {
            normal: {
              width: 1, //尾迹线条宽度
              opacity: 1, //尾迹线条透明度
              curveness: .3, //尾迹线条曲直度
              color: 'white'
            }
          },
          // data: formatData(SHData)
        }, {
          type: 'effectScatter',
          coordinateSystem: 'geo',
          zlevel: 2,
          rippleEffect: { //涟漪特效
            period: 4, //动画时间，值越小速度越快
            brushType: 'stroke', //波纹绘制方式 stroke, fill
            scale: 4, //波纹圆环最大限制，值越大波纹越大
            color: 'white'
          },
          label: {
            normal: {
              show: false,
              position: 'center', //显示位置
              offset: [5, 0], //偏移设置
              formatter: function (params) {//圆环显示文字
                return params.data.name;
              },
              fontSize: 20,
              color: 'white'
            },
            emphasis: {
              show: true
            }
          },
          symbol: 'circle',
          symbolSize: function (val) {
            if (parseInt(val[2]) < 5) {
              return 0;
            } else {
              return val[2] / 10 + 3; //圆环大小
            }

          },
          itemStyle: {
            normal: {
              show: false,
              color: 'red'
            }
          },
          data: convertData11(data),
        }, {
          type: 'effectScatter',
          coordinateSystem: 'geo',
          zlevel: 2,
          symbol: 'image://data:image/gif;base64,R0lGODlhEAAQAMQAAORHHOVSKudfOulrSOp3WOyDZu6QdvCchPGolfO0o/XBs/fNwfjZ0frl3/zy7////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAkAABAALAAAAAAQABAAAAVVICSOZGlCQAosJ6mu7fiyZeKqNKToQGDsM8hBADgUXoGAiqhSvp5QAnQKGIgUhwFUYLCVDFCrKUE1lBavAViFIDlTImbKC5Gm2hB0SlBCBMQiB0UjIQA7',
          symbolSize: 3,
          data: stationArr,
        },
        {
          type: 'map',
          map: 'china',
          aspectScale: 0.75, //长宽比
          showLegendSymbol: false, // 存在legend时显示
          roam: false,
          itemStyle: {
            normal: {
                areaColor: '#0c274b',
                borderColor: '#3C9074',
                borderWidth: 1
            },
            emphasis: {
                areaColor: '#4279A0',
                label: {
                    show: false
                }
              }
            },
          data: data
        },
        //被攻击点
        {
          type: 'effectScatter',
          coordinateSystem: 'geo',
          zlevel: 2,
          rippleEffect: { //涟漪特效
            period: 10, //动画时间，值越小速度越快
            brushType: 'fill', //波纹绘制方式 stroke, fill
            scale: 8, //波纹圆环最大限制，值越大波纹越大
            color: 'red'
          },
          label: {
            normal: {
              show: true,
              position: 'right', //显示位置
              offset: [15, 0], //偏移设置
              formatter: function (params) {//圆环显示文字
                return params.data.name;
              },
              fontSize: 35,
              color: 'white'
            },
            emphasis: {
              show: true
            }
          },
          symbol: 'pin',
          symbolSize: function (val) {
            return 10; //圆环大小
          },
          itemStyle: {
            normal: {
              show: false,
              color: 'red'
            }
          },
          // data: [{
          //   name: '村口壹站',
          //   value: [121.4648, 31.2891],
          // }],
        }

        ]
      };
      this.echartObj.setOption(option);
    },
    getObjectKeys (object) {
      var keys = [];
      for (var property in object)
        keys.push(property);
      return keys;
    },

    getObjectValues (object) {
      var values = [];
      for (var property in object)
        values.push(object[property]);
      return values;
    }
  }
};
</script>
